<template>
    <Button v-if="isBusy" variant="primary" :size="props.size" disabled>
        <Spinner size="sm" />
        <span>{{ $t("BOMTool.Loading") }}</span>
    </Button>
    <Button v-else-if="doesPartExist" as="a" variant="primary" href="/bom/local" :size="props.size">
        <span>{{ $t("BOMTool.ViewBOM") }}</span>
        <Icon name="chevron-right" />
    </Button>
    <Button v-else variant="primary" :size="props.size" @click="onAddButtonClick()">
        <Icon name="plus" />
        <span>{{ $t("BOMTool.AddToBOM") }}</span>
    </Button>
    <LoginDialog ref="loginDialog" @continue="onLoginContinue" />
</template>
<script setup lang="ts">
import type { ButtonVariants } from "../ui";

const { gtag } = useGtag();
const { t } = useI18n();
const { toast } = useToast();
const guestCookie = useGuestCookie();
const loginDialog = useTemplateRef("loginDialog");

const { fetchBOMs, addBOMPart, getDefaultBOM, partExistsInBOM, saveBOM } = useBOM();

const props = defineProps<{ part: BOMSearchPart; size: ButtonVariants["size"] }>();

const anonymousBOM = ref<BOMSearch | null>(null);
const isBusy = ref(false);

const doesPartExist = computed(() => partExistsInBOM(anonymousBOM.value, props.part));

async function addPartToBOM() {
    // there may be no anonymous BOM yet
    let anonBOM = anonymousBOM.value;
    if (!anonBOM) {
        isBusy.value = true;
        anonBOM = await getDefaultBOM();
        anonBOM.Parts = [];
    }
    const updatedBOM = await addBOMPart(anonBOM, props.part);
    if (updatedBOM) {
        anonymousBOM.value = await saveBOM(updatedBOM);
        toast({
            title: t("BOMTool.Success"),
            description: t("BOMTool.SuccessfullyAddedToBOM"),
            variant: "success",
        });
        gtag("event", "bom_tool_add_to_bom", {
            manufacturer_name: props.part.ManufacturerName,
            part_number: props.part.PartNumberScrubbedNonMeaningful,
        });
    }
}

async function onAddButtonClick() {
    if (doesPartExist.value) return;

    if (!guestCookie.value) {
        loginDialog.value?.openDialog();
        return;
    }

    try {
        gtag("event", "bom_tool_open_add_to_bom", { user_type: "Anonymous" });
        addPartToBOM();
    } catch (e) {
        const error = e as Error;
        toast({ title: error.message || t("Global.GenericError"), variant: "warning" });
    } finally {
        isBusy.value = false;
    }
}

function onLoginContinue(isAuth: boolean) {
    if (isAuth) {
        toast({ description: t("BOMTool.PartAddToBomSignInMessage"), variant: "success" });
    } else {
        try {
            addPartToBOM();
        } catch (e) {
            const error = e as Error;
            toast({ title: error.message || t("Global.GenericError"), variant: "warning" });
        } finally {
            isBusy.value = false;
        }
    }
}

onMounted(async () => {
    const bomSearches = await fetchBOMs();
    if (bomSearches.length > 0) anonymousBOM.value = bomSearches[0];
});
</script>
